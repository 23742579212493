import React from "react"
import styled, { css } from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  font-family: "Arimo", sans-serif;
  padding: 50px 20px;
  max-width: 800px;
  margin: 0 auto;

  @media (min-width: 700px) {
    padding: 50px50px;
  }
`

const spacing = css`
  margin: 10px 0;
`

const H1 = styled.h1`
  font-size: 2em;
  ${spacing}
`

const H2 = styled.h2`
  font-size: 1.5em;
  ${spacing}
`

const H3 = styled.h3`
  font-size: 1.25em;
  ${spacing}
`

const Ul = styled.ul`
  list-style: circle !important;
  padding-left: 20px;
  ${spacing}

  li {
    text-indent: 10px;
    ${spacing}
  }
`

const P = styled.p`
  ${spacing}
`

const Bold = styled.strong`
  font-weight: bold;
`

const A = styled.a`
  color: ${props => props.theme.colors.yellow};
  &:hover {
    color: white;
  }
`

const TermsOfService = () => {
  return (
    <Wrapper>
      <H1>Terms and Conditions</H1>
      <P>Last updated: August 25, 2020</P>
      <P>
        Please read these terms and conditions carefully before using Our
        Service.
      </P>
      <H1>Interpretation and Definitions</H1>
      <H2>Interpretation</H2>
      <P>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </P>
      <H2>Definitions</H2>
      <P>For the purposes of these Terms and Conditions:</P>
      <Ul>
        <li>
          <P>
            <Bold>Affiliate</Bold> means an entity that controls, is controlled
            by or is under common control with a party, where
            &quot;control&quot; means ownership of 50% or more of the shares,
            equity interest or other securities entitled to vote for election of
            directors or other managing authority.
          </P>
        </li>
        <li>
          <P>
            <Bold>Account</Bold> means a unique account created for You to
            access our Service or parts of our Service.
          </P>
        </li>
        <li>
          <P>
            <Bold>Country</Bold> refers to: Quebec, Canada
          </P>
        </li>
        <li>
          <P>
            <Bold>Company</Bold> (referred to as either &quot;the Company&quot;,
            &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement)
            refers to Torchlight Press, 295 Rue de la Montagne, Montreal, QC H3C
            4K4.
          </P>
        </li>
        <li>
          <P>
            <Bold>Device</Bold> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </P>
        </li>
        <li>
          <P>
            <Bold>Feedback</Bold> means feedback, innovations or suggestions
            sent by You regarding the attributes, performance or features of our
            Service.
          </P>
        </li>
        <li>
          <P>
            <Bold>Goods</Bold> refer to the items offered for sale on the
            Service.
          </P>
        </li>
        <li>
          <P>
            <Bold>Orders</Bold> mean a request by You to purchase Goods from Us.
          </P>
        </li>
        <li>
          <P>
            <Bold>Promotions</Bold> refer to contests, sweepstakes or other
            promotions offered through the Service.
          </P>
        </li>
        <li>
          <P>
            <Bold>Service</Bold> refers to the Website.
          </P>
        </li>
        <li>
          <P>
            <Bold>Subscriptions</Bold> refer to the services or access to the
            Service offered on a subscription basis by the Company to You.
          </P>
        </li>
        <li>
          <P>
            <Bold>Terms and Conditions</Bold> (also referred as
            &quot;Terms&quot;) mean these Terms and Conditions that form the
            entire agreement between You and the Company regarding the use of
            the Service.
          </P>
        </li>
        <li>
          <P>
            <Bold>Third-party Social Media Service</Bold> means any services or
            content (including data, information, products or services) provided
            by a third-party that may be displayed, included or made available
            by the Service.
          </P>
        </li>
        <li>
          <P>
            <Bold>Website</Bold> refers to Torchlight Press, accessible from{" "}
            <A
              href="https://www.torchlight.press"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://www.torchlight.press
            </A>
          </P>
        </li>
        <li>
          <P>
            <Bold>You</Bold> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </P>
        </li>
      </Ul>
      <H1>Acknowledgment</H1>
      <P>
        These are the Terms and Conditions governing the use of this Service and
        the agreement that operates between You and the Company. These Terms and
        Conditions set out the rights and obligations of all users regarding the
        use of the Service.
      </P>
      <P>
        Your access to and use of the Service is conditioned on Your acceptance
        of and compliance with these Terms and Conditions. These Terms and
        Conditions apply to all visitors, users and others who access or use the
        Service.
      </P>
      <P>
        By accessing or using the Service You agree to be bound by these Terms
        and Conditions. If You disagree with any part of these Terms and
        Conditions then You may not access the Service.
      </P>
      <P>
        You represent that you are over the age of 18. The Company does not
        permit those under 18 to use the Service.
      </P>
      <P>
        Your access to and use of the Service is also conditioned on Your
        acceptance of and compliance with the Privacy Policy of the Company. Our
        Privacy Policy describes Our policies and procedures on the collection,
        use and disclosure of Your personal information when You use the
        Application or the Website and tells You about Your privacy rights and
        how the law protects You. Please read Our Privacy Policy carefully
        before using Our Service.
      </P>
      <H1>Placing Orders for Goods</H1>
      <P>
        By placing an Order for Goods through the Service, You warrant that You
        are legally capable of entering into binding contracts.
      </P>
      <H2>Your Information</H2>
      <P>
        If You wish to place an Order for Goods available on the Service, You
        may be asked to supply certain information relevant to Your Order
        including, without limitation, Your name, Your email, Your phone number,
        Your credit card number, the expiration date of Your credit card, Your
        billing address, and Your shipping information.
      </P>
      <P>
        You represent and warrant that: (i) You have the legal right to use any
        credit or debit card(s) or other payment method(s) in connection with
        any Order; and that (ii) the information You supply to us is true,
        correct and complete.
      </P>
      <P>
        By submitting such information, You grant us the right to provide the
        information to payment processing third parties for purposes of
        facilitating the completion of Your Order.
      </P>
      <H2>Order Cancellation</H2>
      <P>
        We reserve the right to refuse or cancel Your Order at any time for
        certain reasons including but not limited to:
      </P>
      <Ul>
        <li>Goods availability</li>
        <li>Errors in the description or prices for Goods</li>
        <li>Errors in Your Order</li>
      </Ul>
      <P>
        We reserve the right to refuse or cancel Your Order if fraud or an
        unauthorized or illegal transaction is suspected.
      </P>
      <H3>Your Order Cancellation Rights</H3>
      <P>
        Any Goods you purchase can only be returned in accordance with these
        Terms and Conditions and Our Returns Policy.
      </P>
      <P>
        Our Returns Policy forms a part of these Terms and Conditions. Please
        read our Returns Policy to learn more about your right to cancel Your
        Order.
      </P>
      <P>
        Your right to cancel an Order only applies to Goods that are returned in
        the same condition as You received them. You should also include all of
        the products instructions, documents and wrappings. Goods that are
        damaged or not in the same condition as You received them or which are
        worn simply beyond opening the original packaging will not be refunded.
        You should therefore take reasonable care of the purchased Goods while
        they are in Your possession.
      </P>
      <P>
        We will reimburse You no later than 14 days from the day on which We
        receive the returned Goods. We will use the same means of payment as You
        used for the Order, and You will not incur any fees for such
        reimbursement.
      </P>
      <P>
        You will not have any right to cancel an Order for the supply of any of
        the following Goods:
      </P>
      <Ul>
        <li>
          The supply of Goods made to Your specifications or clearly
          personalized.
        </li>
        <li>
          The supply of Goods which according to their nature are not suitable
          to be returned, deteriorate rapidly or where the date of expiry is
          over.
        </li>
        <li>
          The supply of Goods which are not suitable for return due to health
          protection or hygiene reasons and were unsealed after delivery.
        </li>
        <li>
          The supply of Goods which are, after delivery, according to their
          nature, inseparably mixed with other items.
        </li>
        <li>
          The supply of digital content which is not supplied on a tangible
          medium if the performance has begun with Your prior express consent
          and You have acknowledged Your loss of cancellation right.
        </li>
      </Ul>
      <H2>Availability, Errors and Inaccuracies</H2>
      <P>
        We are constantly updating Our offerings of Goods on the Service. The
        Goods available on Our Service may be mispriced, described inaccurately,
        or unavailable, and We may experience delays in updating information
        regarding our Goods on the Service and in Our advertising on other
        websites.
      </P>
      <P>
        We cannot and do not guarantee the accuracy or completeness of any
        information, including prices, product images, specifications,
        availability, and services. We reserve the right to change or update
        information and to correct errors, inaccuracies, or omissions at any
        time without prior notice.
      </P>
      <H2>Prices Policy</H2>
      <P>
        The Company reserves the right to revise its prices at any time prior to
        accepting an Order.
      </P>
      <P>
        The prices quoted may be revised by the Company subsequent to accepting
        an Order in the event of any occurrence affecting delivery caused by
        government action, variation in customs duties, increased shipping
        charges, higher foreign exchange costs and any other matter beyond the
        control of the Company. In that event, You will have the right to cancel
        Your Order.
      </P>
      <H2>Payments</H2>
      <P>
        All Goods purchased are subject to a one-time payment. Payment can be
        made through various payment methods we have available, such as Visa,
        MasterCard, Affinity Card, American Express cards or online payment
        methods (PayPal, for example).
      </P>
      <P>
        Payment cards (credit cards or debit cards) are subject to validation
        checks and authorization by Your card issuer. If we do not receive the
        required authorization, We will not be liable for any delay or
        non-delivery of Your Order.
      </P>
      <H1>Subscriptions</H1>
      <H2>Subscription period</H2>
      <P>
        The Service or some parts of the Service are available only with a paid
        Subscription. You will be billed in advance on a recurring and periodic
        basis (such as daily, weekly, monthly or annually), depending on the
        type of Subscription plan you select when purchasing the Subscription.
      </P>
      <P>
        At the end of each period, Your Subscription will automatically renew
        under the exact same conditions unless You cancel it or the Company
        cancels it.
      </P>
      <H2>Subscription cancellations</H2>
      <P>
        You may cancel Your Subscription renewal either through Your Account
        settings page or by contacting the Company. You will not receive a
        refund for the fees You already paid for Your current Subscription
        period and You will be able to access the Service until the end of Your
        current Subscription period.
      </P>
      <H2>Billing</H2>
      <P>
        You shall provide the Company with accurate and complete billing
        information including full name, address, state, zip code, telephone
        number, and a valid payment method information.
      </P>
      <P>
        Should automatic billing fail to occur for any reason, the Company will
        issue an electronic invoice indicating that you must proceed manually,
        within a certain deadline date, with the full payment corresponding to
        the billing period as indicated on the invoice.
      </P>
      <H2>Fee Changes</H2>
      <P>
        The Company, in its sole discretion and at any time, may modify the
        Subscription fees. Any Subscription fee change will become effective at
        the end of the then-current Subscription period.
      </P>
      <P>
        The Company will provide You with reasonable prior notice of any change
        in Subscription fees to give You an opportunity to terminate Your
        Subscription before such change becomes effective.
      </P>
      <P>
        Your continued use of the Service after the Subscription fee change
        comes into effect constitutes Your agreement to pay the modified
        Subscription fee amount.
      </P>
      <H2>Refunds</H2>
      <P>
        Except when required by law, paid Subscription fees are non-refundable.
      </P>
      <P>
        Certain refund requests for Subscriptions may be considered by the
        Company on a case-by-case basis and granted at the sole discretion of
        the Company.
      </P>
      <H1>Promotions</H1>
      <P>
        Any Promotions made available through the Service may be governed by
        rules that are separate from these Terms.
      </P>
      <P>
        If You participate in any Promotions, please review the applicable rules
        as well as our Privacy policy. If the rules for a Promotion conflict
        with these Terms, the Promotion rules will apply.
      </P>
      <H1>User Accounts</H1>
      <P>
        When You create an account with Us, You must provide Us information that
        is accurate, complete, and current at all times. Failure to do so
        constitutes a breach of the Terms, which may result in immediate
        termination of Your account on Our Service.
      </P>
      <P>
        You are responsible for safeguarding the password that You use to access
        the Service and for any activities or actions under Your password,
        whether Your password is with Our Service or a Third-Party Social Media
        Service.
      </P>
      <P>
        You agree not to disclose Your password to any third party. You must
        notify Us immediately upon becoming aware of any breach of security or
        unauthorized use of Your account.
      </P>
      <P>
        You may not use as a username the name of another person or entity or
        that is not lawfully available for use, a name or trademark that is
        subject to any rights of another person or entity other than You without
        appropriate authorization, or a name that is otherwise offensive, vulgar
        or obscene.
      </P>
      <H1>Intellectual Property</H1>
      <P>
        The Service and its original content (excluding Content provided by You
        or other users), features and functionality are and will remain the
        exclusive property of the Company and its licensors.
      </P>
      <P>
        The Service is protected by copyright, trademark, and other laws of both
        the Country and foreign countries.
      </P>
      <P>
        Our trademarks and trade dress may not be used in connection with any
        product or service without the prior written consent of the Company.
      </P>
      <H1>Your Feedback to Us</H1>
      <P>
        You assign all rights, title and interest in any Feedback You provide
        the Company. If for any reason such assignment is ineffective, You agree
        to grant the Company a non-exclusive, perpetual, irrevocable, royalty
        free, worldwide right and license to use, reproduce, disclose,
        sub-license, distribute, modify and exploit such Feedback without
        restriction.
      </P>
      <H1>Links to Other Websites</H1>
      <P>
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by the Company.
      </P>
      <P>
        The Company has no control over, and assumes no responsibility for, the
        content, privacy policies, or practices of any third party web sites or
        services. You further acknowledge and agree that the Company shall not
        be responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with the use of or
        reliance on any such content, goods or services available on or through
        any such web sites or services.
      </P>
      <P>
        We strongly advise You to read the terms and conditions and privacy
        policies of any third-party web sites or services that You visit.
      </P>
      <H1>Termination</H1>
      <P>
        We may terminate or suspend Your Account immediately, without prior
        notice or liability, for any reason whatsoever, including without
        limitation if You breach these Terms and Conditions.
      </P>
      <P>
        Upon termination, Your right to use the Service will cease immediately.
        If You wish to terminate Your Account, You may simply discontinue using
        the Service.
      </P>
      <H1>Limitation of Liability</H1>
      <P>
        Notwithstanding any damages that You might incur, the entire liability
        of the Company and any of its suppliers under any provision of this
        Terms and Your exclusive remedy for all of the foregoing shall be
        limited to the amount actually paid by You through the Service or 100
        USD if You haven't purchased anything through the Service.
      </P>
      <P>
        To the maximum extent permitted by applicable law, in no event shall the
        Company or its suppliers be liable for any special, incidental,
        indirect, or consequential damages whatsoever (including, but not
        limited to, damages for loss of profits, loss of data or other
        information, for business interruption, for personal injury, loss of
        privacy arising out of or in any way related to the use of or inability
        to use the Service, third-party software and/or third-party hardware
        used with the Service, or otherwise in connection with any provision of
        this Terms), even if the Company or any supplier has been advised of the
        possibility of such damages and even if the remedy fails of its
        essential purpose.
      </P>
      <P>
        Some states do not allow the exclusion of implied warranties or
        limitation of liability for incidental or consequential damages, which
        means that some of the above limitations may not apply. In these states,
        each party's liability will be limited to the greatest extent permitted
        by law.
      </P>
      <H1>&quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer</H1>
      <P>
        The Service is provided to You &quot;AS IS&quot; and &quot;AS
        AVAILABLE&quot; and with all faults and defects without warranty of any
        kind. To the maximum extent permitted under applicable law, the Company,
        on its own behalf and on behalf of its Affiliates and its and their
        respective licensors and service providers, expressly disclaims all
        warranties, whether express, implied, statutory or otherwise, with
        respect to the Service, including all implied warranties of
        merchantability, fitness for a particular purpose, title and
        non-infringement, and warranties that may arise out of course of
        dealing, course of performance, usage or trade practice. Without
        limitation to the foregoing, the Company provides no warranty or
        undertaking, and makes no representation of any kind that the Service
        will meet Your requirements, achieve any intended results, be compatible
        or work with any other software, applications, systems or services,
        operate without interruption, meet any performance or reliability
        standards or be error free or that any errors or defects can or will be
        corrected.
      </P>
      <P>
        Without limiting the foregoing, neither the Company nor any of the
        company's provider makes any representation or warranty of any kind,
        express or implied: (i) as to the operation or availability of the
        Service, or the information, content, and materials or products included
        thereon; (ii) that the Service will be uninterrupted or error-free;
        (iii) as to the accuracy, reliability, or currency of any information or
        content provided through the Service; or (iv) that the Service, its
        servers, the content, or e-mails sent from or on behalf of the Company
        are free of viruses, scripts, trojan horses, worms, malware, timebombs
        or other harmful components.
      </P>
      <P>
        Some jurisdictions do not allow the exclusion of certain types of
        warranties or limitations on applicable statutory rights of a consumer,
        so some or all of the above exclusions and limitations may not apply to
        You. But in such a case the exclusions and limitations set forth in this
        section shall be applied to the greatest extent enforceable under
        applicable law.
      </P>
      <H1>Governing Law</H1>
      <P>
        The laws of the Country, excluding its conflicts of law rules, shall
        govern this Terms and Your use of the Service. Your use of the
        Application may also be subject to other local, state, national, or
        international laws.
      </P>
      <H1>Disputes Resolution</H1>
      <P>
        If You have any concern or dispute about the Service, You agree to first
        try to resolve the dispute informally by contacting the Company.
      </P>
      <H1>For European Union (EU) Users</H1>
      <P>
        If You are a European Union consumer, you will benefit from any
        mandatory provisions of the law of the country in which you are resident
        in.
      </P>
      <H1>United States Federal Government End Use Provisions</H1>
      <P>
        If You are a U.S. federal government end user, our Service is a
        &quot;Commercial Item&quot; as that term is defined at 48 C.F.R. §2.101.
      </P>
      <H1>United States Legal Compliance</H1>
      <P>
        You represent and warrant that (i) You are not located in a country that
        is subject to the United States government embargo, or that has been
        designated by the United States government as a &quot;terrorist
        supporting&quot; country, and (ii) You are not listed on any United
        States government list of prohibited or restricted parties.
      </P>
      <H1>Severability and Waiver</H1>
      <H2>Severability</H2>
      <P>
        If any provision of these Terms is held to be unenforceable or invalid,
        such provision will be changed and interpreted to accomplish the
        objectives of such provision to the greatest extent possible under
        applicable law and the remaining provisions will continue in full force
        and effect.
      </P>
      <H2>Waiver</H2>
      <P>
        Except as provided herein, the failure to exercise a right or to require
        performance of an obligation under this Terms shall not effect a party's
        ability to exercise such right or require such performance at any time
        thereafter nor shall be the waiver of a breach constitute a waiver of
        any subsequent breach.
      </P>
      <H1>Translation Interpretation</H1>
      <P>
        These Terms and Conditions may have been translated if We have made them
        available to You on our Service. You agree that the original English
        text shall prevail in the case of a dispute.
      </P>
      <H1>Changes to These Terms and Conditions</H1>
      <P>
        We reserve the right, at Our sole discretion, to modify or replace these
        Terms at any time. If a revision is material We will make reasonable
        efforts to provide at least 30 days' notice prior to any new terms
        taking effect. What constitutes a material change will be determined at
        Our sole discretion.
      </P>
      <P>
        By continuing to access or use Our Service after those revisions become
        effective, You agree to be bound by the revised terms. If You do not
        agree to the new terms, in whole or in part, please stop using the
        website and the Service.
      </P>
      <H1>Contact Us</H1>
      <P>
        If you have any questions about these Terms and Conditions, You can
        contact us:
      </P>
      <Ul>
        <li>By email: help@torchlight.press</li>
      </Ul>
    </Wrapper>
  )
}

export default TermsOfService
